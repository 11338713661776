
:root{
    --menu-shadow-rgb:101, 102, 103;
    --theme-color: #2967AC;
}
#searchConditionHeader {
    padding: 0;
    align-items: center;
}
.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border-bottom: 1px solid #e1e7ee;
}
.headerMenu {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px;
    width: 100%;
    height: 54px;
}
.logoWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 12px 0 11px;
}
.logo {
    display: block;
    object-fit: cover;
    width: 116px;
    height: auto;
}
.icon img {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 20px;
}
.breadcrumb-shadow {
    box-shadow: 0 2px 5px 0 rgba(var(--menu-shadow-rgb), 25%);
}
.button-custom {
    width: 15px !important;
    height: 15px !important;
    padding: 0 15px 10px 0 !important;
}
.content{
    padding-top: 50px;
}
.div-card.pc-min-width{
    min-width: auto;
}
.sticky-left{
    left: 0px;
    z-index: 10;
}
.sticky-left02{
    position: sticky !important;
    left: 40px;
    z-index: 10;
}
.base-table-area{
    max-height: 620px;
}
td.inner-table{
    padding: 0 6px;
}

.dropdown-menu{
    padding: 0;
}
.dropdown-menu.show {
    display: block;
    max-height: 400px;
    overflow-y: auto;
}
.dropdown-menu.close {
    display: none;
}
.dropdown-item{
    line-height: 1;
    padding: 5px 6px !important;
    height: auto !important;
}
.dropdown-item:hover, .dropdown-item:focus{
    background-color: #2967ac;
    color: #fff;
}

.modal {
    display: none;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}
.modal-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.modal-content {
    background-color: #fcfaf7;
    box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2),0 7px 20px 0 rgba(0,0,0,0.17);
    animation-name: modalopen;
    animation-duration: 0.2s;
    max-height: 90vh;
    max-width: 1140px;
    overflow: hidden;
}
@keyframes modalopen {
    from {opacity: 0;transform: translateY(-20px);}
    to {opacity: 1 translateY(0);}
}
.modal-header h1 {
    margin: 1rem 0;
}
.modal-header {
    background-color: #fcfaf7;

    padding: 3px 15px;
    display: flex;
    justify-content: space-between;
}
.modalClose {
    font-size: 24px;
    font-weight: normal;
    margin-left: auto;
}
.modalClose:hover {
    cursor: pointer;
}
.modal-body {
    padding: 10px 20px;
    color: black;
    height: 100%;
    overflow-y: auto;
}
.modal-title {
    font-size: 17px;
    color: #595959;
}
@media (max-width: 991px){
    .modal-content{
        width: 90%;
    }
    .modalClose {
        font-size: 16px;
    }
}


.modal.is-dialog .modal-content {
    box-shadow: none;
    width: 100%;
}
.modal.is-dialog .modal-content .btn-lg {
    height: 32px !important;
}
@media (min-width: 576px) {
    .modal.is-dialog .modal-content {
        max-width: var(--bs-modal-width);
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .modal.is-dialog .modal-content .modal-body {
        min-height: 97.5px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

@media (max-width: 991px){
    main {
        padding-bottom: 50px;
    }
}




@media (max-width: 991px){
    .content {
        padding-bottom: 20px;
    }
    .div-main {
        padding-bottom: 0;
    }
    .div-main.dev-pc {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

table.custom-table.base-table>tbody>tr:nth-of-type(odd)>*{
    background-color: #fff;
    --bs-table-bg-type: #fff;
}
.is-sp{
    display: none;
}

.sticky-left:before,
.sticky-left02:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 1px solid #CCCCCC;
    z-index: -1;
}
.all-check,
.check-cell {
    text-align: center;
}
.check-cell__ceckbox > span {
    display: none;
}
.check-cell.is-hover {
    --bs-table-color-state: var(--bs-table-hover-color);--bs-table-bg-state: var(--bs-table-hover-bg);
}
table.custom-table.base-table thead th.is-hover {
    --bs-table-color-state: var(--bs-table-hover-color);--bs-table-bg-state: var(--bs-table-hover-bg);
}
table.custom-table.base-table td.is-disabled {
    --bs-table-bg-type: #eeeeee !important;
    background-color: #eeeeee !important;
}
table.custom-table.base-table tbody:nth-child(odd) tr td{
    --bs-table-bg-type: #f7f7f7;
    background-color: #f7f7f7;
}

.selectFlg {
    pointer-events: none;
}


.has-label {
    cursor: pointer; /* マウスポインターを指に変更 */
}

.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    background-color: #fff;
    border: 1px solid #CCCCCC;
    padding: 5px;
    border-radius: 3px;
    white-space: pre-line; /* 改行を反映 */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
    z-index: 1000; /* 高いz-indexを設定 */
    min-height: 50px;
    max-width: 200px;
    top: 0;
    left: 0;
    box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.2);
}

@media (max-width: 991px){
    .has-label {
        cursor: default;
    }
    .tooltip {
        display: none;
    }
    .is-pc {
        display: none !important;
    }
    .div-card.pc-min-width{
        min-width: auto;
    }
    .base-table-area{
        max-height: none;
        overflow-x: hidden;
    }
    table.custom-table.base-table>tbody>tr:hover,
    table.custom-table.base-table>tbody>tr:hover td{
        --bs-table-color-state: inherit;
        --bs-table-bg-state: inherit;
    }
    table.custom-table.base-table tbody:nth-child(odd) tr td{
        --bs-table-bg-state: #fff;

    }
    /*
    table.custom-table.base-table>tbody.is-changed{
        --bs-table-bg-state: var(--bs-table-hover-bg);
        background-color: var(--bs-table-hover-bg);
    }
    */
    .table.base-table>tbody{
        display: block;
        margin-bottom: 20px;
        background-color: #fff;
        padding: .5rem !important;
        border-radius: 0.25rem;
        border: 1px solid #dee2e6;
    }
    .custom-table.base-table th, .custom-table.base-table td{
        border: none;
    }
    .sticky-left:before{
        border: none;
    }
    .sticky-left{
        text-align: left!important;
    }
    .col-lg-4{
        width: 100%!important;
    }
    .justify-content-end, .div-item-title.dev-pc, .dev-pc.div-item-group-title{
        justify-content: flex-start!important;
    }
    .div-assist-button{
        width: auto!important;
        display: flex;
        align-items: center;
        padding: 0;
    }
    .form-select.dev-pc:not([multiple]) {
        height: 40px;
    }
    .form-control.dev-pc{
        height: 40px;
    }
    .div-item-input{
        padding-right: 0;
    }
    .div-item-input.col-lg-7{

        margin-right: 7px;
    }
    .col-lg-8{
        padding: 0;
        margin: 0;
    }
    .form-suggest-option-pc{
        padding: 5px 6px!important;
        height: auto!important;
    }
    .div-item-title{
        display: block;
        width: 100%!important;
        margin: 6px 0 2px;
    }
    .div-column-resize-minus.dev-pc.col-lg-9, .dev-pc.col-lg-9.div-item-value, .dev-pc.col-lg-9.div-item-input{
        width: 100%;
    }
    .col-lg-8 > .row.is-base{
        margin: auto;
    }
    .div-column-resize-minus.dev-pc.col-lg-11, .dev-pc.col-lg-11.div-item-value, .dev-pc.col-lg-11.div-item-input{
        width: 100%;
    }
    /* .row.col-lg-9{
        padding: 0;
        margin: auto;
    } */
    /* .col-lg-10.dev-pc.div-item-input{
        width: 100%;
    } */
    .is-base-manager{
        margin: auto;
        width: auto;
    }
    .is-base-manager .col-lg-10.dev-pc.div-item-input{
        width: 100%;
    }
    .col-lg-1.div-column-resize-plus.dev-pc.div-item-title{
        min-height: 1px!important;
    }
    #searchCondition, #searchCondition2{
        padding: 0 20px;
    }
    #searchCondition{
        margin-bottom: 10px;
    }
    .headerMenu{
        height: 43px;
        padding-left: 20px !important;
        padding-right: 10px !important;
    }
    .icon img{
        width: 30px!important;
        height: 30px!important;
        margin-left: 8px!important;
    }
    .dev-pc.div-card-title.col-auto.row{
        padding: 0;
    }
    button.me-lg-2.dev-pc.btn.btn-lg.btn-positive, button.ms-lg-2.dev-pc.btn.btn-lg.btn-negative{
        margin: 8px 0;
        padding: 0 14px;
        height: 44px;
        line-height: 22px;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        border-radius: 3px;
    }
    .check-cell, .is-item {
        display: flex !important;
        gap:10px;
        background-color: #fff !important;
        border-top: none !important;
        position: relative;
    }
    .check-cell[data-title], .is-item[data-title] {
        padding-top: 26px!important;
    }
    .check-cell[data-title]::before, .is-item[data-title]::before{
        content: attr(data-title);
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        text-align: left;
        padding: 2px 5px;
        background-color: rgba(33, 37, 41,.1) !important;
        font-size: .875em;
        border: 0;
        
    }
    .check-cell[data-label]::after, .is-item[data-label]::after {
        content: attr(data-label);
        display: inline-block;
        
    }
    .check-cell__inner {
        display: flex;
        /*justify-content: center;*/
        align-items: center;
        flex-wrap: wrap;
    }
    .check-cell__ceckbox > span {
        display: inline-block;
        padding-left: 5px;
        margin-right: 5px;
    }
    tbody td:first-child {
        font-weight: bold;
        margin-top: 0!important;
    }

    .dropdown-menu{
        width: 80%;
    }
    .d-flex.flex-column.flex-lg-row.mx-2{
        margin-top: 10px;
    }
    button.me-lg-2.dev-pc.btn.btn-lg.btn-positive, button.ms-lg-2.dev-pc.btn.btn-lg.btn-negative{
        margin: 8px 0;
        padding: 0 14px;
        height: 32px;
        line-height: 16px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 3px;
    }
    .custom-table.base-table th, .custom-table.base-table td {
        padding: 5px 5px 10px 5px;
        vertical-align: middle;
    }
    .custom-table.base-table td:first-child {
        background-color: rgba(33, 37, 41, 0.25)!important;
    }
    .custom-table.base-table td br{
        display: none;
    }
    .is-modal{
        display: flex!important;
        align-items: center;
    }
    .is-modal .btn-info{
        order: 3;
    }
    .is-sp{
        display: block;
    }
}


@media (max-width: 991px) {
    .table-wrapper {
        height: auto;
        position: static;
    }
    table.base-table,.base-table tbody,.base-table td,.base-table th,.base-table tr {
        display: block /* !important */;
        height: auto !important;
    }
    .base-table thead {
        display: none;
    }

    .check-cell {
        display: flex !important;
        gap:10px;
        background-color: #fff !important;
        border-top: none !important;
        position: relative;
    }
    .check-cell[data-title] {
        padding-top: 40px;
    }
    .check-cell[data-title]::before {
        content: attr(data-title);
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 23px;
        text-align: left;
        padding: 2px 5px;
        background-color: #d0d8e2;
        
    }
    .check-cell[data-label]::after {
        content: attr(data-label);
        display: block;
    }
    tbody td:first-child {
        font-weight: bold;
        margin-top: 20px;
    }
    .fixed-table tbody td:first-child::after,
    .fixed-table thead tr th:first-child::after {
        display: none;
    }
    .check-cell.is-checked::after {
        color: #0064ff;
    }
}
select {
    font-size: 16px;
}
select option {
    padding-top: 5px;
    padding-bottom: 5px;
}

@media (max-width: 991px) {
    .fixed-bottom.pc-button-area{
        position: static;
    }
    .fixed-bottom .d-flex .me-auto{
        display: none;
    }
    .fixed-bottom .d-flex .btn-block{
        width: 100%;
    }
    .btn-lg {
        display: block;
        height: 44px !important;
        line-height: 22px !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
@media (max-width: 991px) {
    .result-title + .title-hr {
        margin-bottom: 15px;
    }
    .result-title > .row{
        width: 100%;
        margin: 0;
    }
    .result-title .row>*{
        padding-right: 5px;
        padding-left: 5px;
    }
    .cnt-font {
        font-size: 15px;
    }
}

@media (max-width: 991px) {
    #searchCondition_detail > .row{
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: calc(var(--bs-gutter-x)* .5);
    }
    #searchCondition02_detail > .row.mx-2{
        padding-right: calc(var(--bs-gutter-x)* .5);
        padding-left: 0;
        margin-left: 0 !important;
    }

}
.page-top,
.page-bottom {
    display: none;
}
@media (max-width: 991px) {
    .page-top,
    .page-bottom {
        display: block;
    }
    .page-bottom {
        position: fixed;
        bottom: 60px;
        right: 20px;
        line-height: 1;
        z-index: 99;
        opacity: .5;
    }
    .page-bottom a {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 7px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' class='bi bi-arrow-down-square-fill' viewBox='0 0 16 16'%3E%3Cpath d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5a.5.5 0 0 1 1 0z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
    }
    .page-top {
        position: fixed;
        top: 90px;
        right: 20px;
        line-height: 1;
        z-index: 99;
        opacity: .5;
    }
    .page-top a {
        display: inline-block;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 7px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' class='bi bi-arrow-up-square-fill' viewBox='0 0 16 16'%3E%3Cpath d='M2 16a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2zm6.5-4.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 1 0z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
    }
}
.fixed-bottom {
    box-shadow: 0 -2px 5px 0 rgba(var(--menu-shadow-rgb), 25%);
}

.addClass-block {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3em;
}
@media (max-width: 991px) {
    .addClass-block {
        display: block;
        line-clamp: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
        overflow: visible;
        text-overflow: clip;
        max-height: none;
    }
}

option.status-opt-group {
    background-color: #c5dff9 !important;
}

@media (max-width: 991px) {
    .search-section .col-lg-12.dev-pc.div-item-input {
        width: 100%;
    }
    .search-section .div-item-input {
        width: 100% !important;
    }
    .search-section .div-item-input.has-right-btn {
        width: calc(100% - 34px) !important;
    }
    
}
.form-check-input+label {
    margin-left: 0;
}
@media (max-width: 1400px) {
    .form-check-input+label.me-3 {
        margin-right: 0.4em !important;
    }
}
.div-item-title {
    position: relative;
}
#mail-help-btn {
    position: absolute;
    right: 10px;
}
.dropdown-custom-wrap {
    position: relative;
}
.dropdown-custom-wrap .dropdown-menu{
    min-width: 100%;
}
.xfw-select-box.dropdown{
    position: relative;
}
.dropdown-toggle.on-drop {
    cursor: pointer;
}
/** 列非表示時 */
.omit-col {
    display: none !important;
}
/** ソート時のアイコン */
th[sorted="ASC"]::after {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' class='bi bi-arrow-up' viewBox='1 1 15 15'><path fill-rule='evenodd' d='M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z'/></svg>");
    height: 13px;
    width: 13px;
}
th[sorted="DESC"]::after {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' class='bi bi-arrow-down' viewBox='1 1 15 15'><path fill-rule='evenodd' d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z'/></svg>");
    height: 13px;
    width: 13px;
}

/** モバイルではタッチしたときにツールチップを表示 */
.div-tooltip .for-mobile {
    position: absolute;
    font-size: 15px;
    border: 1px solid;
    top: 0;
    left: 50%;
    /* transform: translate(-20%,calc(-100% - 10px)); X-DD の定義はこれ */
    background: #ffffff;
    width: 40vw;
    color: #000;
    padding: 1px;
    white-space: pre-wrap;
    z-index: 1032;
}
.div-tooltip.tooltip-bottom .for-mobile {
    top: 20px;
    left: -20px;
    transform: none;
}
